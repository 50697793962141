/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/styles";

body {
  margin: 0;
  padding: 0;
  background: $color-common-100;
  overflow-y: overlay;
}

h1 {
  margin: 0;
  @include title-1-regular;
  color: $color-common-700;
}

h2 {
  margin: 0;
  @include title-2-regular;
  color: $color-common-700;
}

h3 {
  margin: 0;
  @include title-3-regular;
  color: $color-common-700;
}

p,
label,
input,
a,
span {
  margin: 0;
  @include text-1-regular;
  color: $color-common-600;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 14px;
  background-clip: padding-box;
  background-color: $color-common-500;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-resizer {
  display: none;
}

@media (max-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1376px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1376px) and (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}
