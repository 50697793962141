$color-white: #ffffff;

$color-common-900: #0f172a;
$color-common-800: #1e293b;
$color-common-700: #334155;
$color-common-600: #475569;
$color-common-500: #64748b;
$color-common-400: #94a3b8;
$color-common-300: #cbd5e1;
$color-common-200: #e2e8f0;
$color-common-100: #f1f5f9;

$color-primary-900: #490949;
$color-primary-800: #5b115b;
$color-primary-700: #681868;
$color-primary-600: #781f78;
$color-primary-500: #933093;
$color-primary-400: #bb5bbb;
$color-primary-300: #df8ddf;
$color-primary-200: #ebc4eb;
$color-primary-100: #ffecfd;

$color-success-900: #064e3b;
$color-success-800: #065f46;
$color-success-700: #047857;
$color-success-600: #059669;
$color-success-500: #10b981;
$color-success-400: #34d399;
$color-success-300: #6ee7b7;
$color-success-200: #a7f3d0;
$color-success-100: #d1fae5;

$color-attention-900: #78350f;
$color-attention-800: #92400e;
$color-attention-700: #b45309;
$color-attention-600: #d97706;
$color-attention-500: #f59e0b;
$color-attention-400: #fbbf24;
$color-attention-300: #fcd34d;
$color-attention-200: #fde68a;
$color-attention-100: #fef3c7;

$color-warning-900: #7f1d1d;
$color-warning-800: #991b1b;
$color-warning-700: #b91c1c;
$color-warning-600: #dc2626;
$color-warning-500: #ef4444;
$color-warning-400: #f87171;
$color-warning-300: #fca5a5;
$color-warning-200: #fecaca;
$color-warning-100: #fee2e2;

$color-secondary-100: #e0f2fe;
$color-secondary-200: #bae6fd;
$color-secondary-300: #7dd3fc;
$color-secondary-400: #38bdf8;
$color-secondary-500: #0ea5e9;
$color-secondary-600: #0284c7;
$color-secondary-700: #0369a1;
$color-secondary-800: #075985;
$color-secondary-900: #0c4a6e;

$color-teal-100: #ccfbf1;
$color-teal-200: #99f6e4;
$color-teal-300: #5eead4;
$color-teal-400: #2dd4bf;
$color-teal-500: #14b8a6;
$color-teal-600: #0d9488;
$color-teal-700: #0f766e;
$color-teal-800: #115e59;
$color-teal-900: #134e4a;

$color-indigo-100: #e0e7ff;
$color-indigo-200: #c7d2fe;
$color-indigo-300: #a5b4fc;
$color-indigo-400: #818cf8;
$color-indigo-500: #6366f1;
$color-indigo-600: #4f46e5;
$color-indigo-700: #4338ca;
$color-indigo-800: #3730a3;
$color-indigo-900: #312e81;

$color-rose-100: #ffe4e6;
$color-rose-200: #fecdd3;
$color-rose-300: #fda4af;
$color-rose-400: #fb7185;
$color-rose-500: #f43f5e;
$color-rose-600: #e11d48;
$color-rose-700: #be123c;
$color-rose-800: #9f1239;
$color-rose-900: #881337;
