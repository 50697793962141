@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Raleway:wght@400;700&display=swap");

@mixin title-1-bold {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin title-1-regular {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin title-2-bold {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin title-2-regular {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin title-3-bold {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin title-3-regular {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin text-title-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin text-title-regular {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@mixin text-1-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin text-1-regular {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin text-2-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@mixin text-2-regular {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

@mixin text-3-bold {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin text-3-regular {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
